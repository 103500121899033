import React from "react";
import LocalHeader from "../../shared/localHeader";

export default function LocalHeaderProgram() {
  return (
    <section  id="localHeaderProgram">
      <LocalHeader
        titlesize="display-1"
        title="Fellow werden"
        buttonGridTemplateAreas={`'. . vblack'`}
        buttonTextBackgroundColor="black"
        buttonTextColor="white"
      />
    </section>
  );
}
