import React from "react";
import { useEffect } from "react"


export default function Break() {
  useEffect(() => {
   /*  const script = document.createElement('script');
  
    script.src = "https://mps-files.de/byte/js/iframe.js";
    script.async = true;
  
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    } */
  }, []);
  return (
    
    <section id="breakProgram">
        <div className="grid p-grid-standard xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 content no-padding-top">
            <div className="col-12 body-1">
              <div className="note bc-lightgrey p-30 lg:p-50">
                <p>
                Nach 5 erfolgreichen Batches mit zahlreichen Projekten aus der Bayerischen Verwaltung blicken wir zurück auf eine spannende und produktive Zeit bei der Digitalschmiede Bayern. Der Batch #5 endete am 31. Oktober 2024, und wir sind stolz auf die innovativen Lösungen, die unsere Fellows entwickelt haben.
                <br></br>
                Wenn du Interesse hast, Teil eines zukünftigen Batches zu werden oder dich schon jetzt mit uns in Verbindung zu setzen, melde dich gerne unter  <a className="link" href="mailto:digitalschmiede@mantro.studio">digitalschmiede@mantro.studio</a>
                </p>
            </div>
            </div>
         
        </div>     

    </section>
    
  );
}